import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

import Modal from "../bootstrap/modal";

export default function TermModal(props) {
  const { onClose } = props;

  return (
    <Modal
      title={"Critical Information Summary"}
      onClose={onClose}
      show
      footer={
        <Row>
          <Col xs="auto" className="ml-auto"></Col>
          <Col xs="auto">
            <Button variant="darkblue" type="button" onClick={() => onClose()}>
              {"Close"}
            </Button>
          </Col>
        </Row>
      }
      size={"xl"}
      bodyProps={{
        style: {padding: 0},
      }}
    >
      <Container className="no-gutters no-padding" fluid>
        <Row>
          <Col>
            <object data="/pdfs/GetGud-Critical-Information-Summary.pdf#scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0" type="application/pdf" style={{border:0, minHeight: 600, width: "100%"}}>
              <div>No online PDF viewer installed</div>
            </object>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
