import React from "react";
import {
  FormGroup,
  Col,
} from "react-bootstrap";

export default function FieldTemplate(props) {
  return (
    <Col md={6} {...props}>
      <FormGroup>{props.children}</FormGroup>
    </Col>
  );
}