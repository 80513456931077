import React from "react";

import { Modal } from "react-bootstrap";

export default function ContactUs({showContactUs, setShowContactUs}) {
  function hideContactUs() {
    setShowContactUs(false);
  }
  return (<>
    <Modal show={showContactUs} onHide={hideContactUs}>
      <Modal.Header closeButton>
        <Modal.Title>{"Contact Us"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <code>
          {"Email: "}<a target="_blank"href={"mailto:support@getgud.gg"}>{"support@getgud.gg"}</a><br />
          {"Tel: "}<a target="_blank" href={"tel:61272534400"}>{"02-7253-4400"}</a>
        </code>
      </Modal.Body>
    </Modal>
  </>);
}