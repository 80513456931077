import { Link } from "gatsby";
import React, {useState} from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logoWhite from "../../images/logo-white.svg";
import ContactUs from "./contact-us";

export default function Header() {
  const [showContactUs, setShowContactUs] = useState(false);

  return (<>
    <ContactUs showContactUs={showContactUs} setShowContactUs={setShowContactUs} />
    <header className="header">
      <Container>
        <Navbar bg="orange" variant="dark" expand="lg">
          <Navbar.Brand href="/">
            <img src={logoWhite} alt="logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link className="nav-link" to="/?section=about">About</Link>
              <Link className="nav-link" to="/?section=plans">Plans</Link>
              <Link className="nav-link" to="/?section=why">Why GetGud?</Link>
              <a className="nav-link" onClick={() => window.zE('messenger', 'open')}>Contact Us</a>
              <Link className="nav-link" to="/login">Login</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  </>);
}
