import { gql } from "@apollo/client";
export const createMobileVerificationMutation = gql`
  mutation createMobileVerification(
    $mobileNumber: String
    $captcha: String
    $captchaKey: String
  ) {
    classMethods {
      MobileVerification {
        createMobileVerification(
          mobileno: $mobileNumber
          captcha: $captcha
          captchaKey: $captchaKey
        )
      }
    }
  }
`;
export const validateMobileVerificationMutation = gql`
  mutation validateMobileVerification($code: String, $mobileNumber: String) {
    classMethods {
      MobileVerification {
        validateMobileVerification(code: $code, mobileno: $mobileNumber)
      }
    }
  }
`;

export const validateMobileVerificationQuery = gql`
  query validateMobileVerification($code: String, $mobileNumber: String) {
    classMethods {
      MobileVerification {
        validateMobileVerification(code: $code, mobileno: $mobileNumber)
      }
    }
  }
`;

export const resendVerificationMutation = gql`
  mutation resendVerification($mobileNumber: String) {
    classMethods {
      MobileVerification {
        resendMobileVerification(mobileNumber: $mobileNumber)
      }
    }
  }
`;

export function createMobileVerificationResult({ data }) {
  return data.classMethods.MobileVerification.createMobileVerification;
}
