import React, {useRef, Fragment} from "react";
import {Container} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";

import {getFormData} from "../components/manager/form";
import Main from "../components/main";
import Header from "../components/section/header";
import Footer from "../components/section/footer";
import SEO from "../components/section/seo";
import Purchase from "../components/section/purchase";

export default function Index(props) {
  return (
    <Main>
      <SEO title="GETGUD - Register Form">
        <meta name="description" content="GetGud is an Internet Service Provider build for gaming performance" />
      </SEO>
      <Header />
      <RegisterForm {...props} />
      <Footer />
    </Main>
  );
}

function RegisterForm(props) {
  const myRef = useRef(null);
  const [step, setStep] = useAsyncSetState({
    addressSelector: true,
    productSelector: false,
    purchase: false,
  });

  const {building, selectedProduct} = getFormData();
  console.log({building, selectedProduct});
  return (
    <Fragment>
      <div className="plan-section" ref={myRef}>
        <Container>
          <Purchase
            building={building}
            product={selectedProduct}
            {...props}
          />
        </Container>
      </div>
    </Fragment>
  );
}
