import { gql } from "@apollo/client";

export const getPurchaseResult = ({ data }) =>
  data.classMethods.User.purchaseAndCreateUser;
export const purchaseAndCreateUserMutation = gql`
  mutation purchaseAndCreateUser(
    $voucherCode: String
    $buildingCode: String
    $verificationCode: String
    $products: [GQLRadiusCircuitsProductOptionsInput]
    $cardNumber: String
    $cardCCV: String
    $cardName: String
    $cardExpiry: String
    $streetNo: String
    $address: String
    $suburb: String
    $state: String
    $postCode: String
    $building: String
    $unitNo: String
    $firstName: String
    $lastName: String
    $mobileNumber: String
    $email: String
    $countryId: ID
    $password: String
  ) {
    classMethods {
      User {
        purchaseAndCreateUser(
          voucherCode: $voucherCode
          buildingCode: $buildingCode
          verificationCode: $verificationCode
          products: $products
          creditCard: {
            cardNumber: $cardNumber
            cardCCV: $cardCCV
            cardName: $cardName
            cardExpiry: $cardExpiry
          }
          streetNo: $streetNo
          address: $address
          suburb: $suburb
          state: $state
          postCode: $postCode
          building: $building
          unitNo: $unitNo
          firstName: $firstName
          lastName: $lastName
          mobileCountry: "au"
          mobileNumber: $mobileNumber
          email: $email
          countryId: $countryId
          password: $password
          provisioning: false
        ) {
          id
          userName
          newPassword
        }
      }
    }
  }
`;


export const getSiteInfoQuery = gql`
query getSiteInfo {
  siteInfo {
    code
    auEwayClientKey
  }
}`;

export function getSiteInfoResult(result) {
  return result?.data?.siteInfo;
}
